import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
//
Vue.use(Vuetify);

const options = {
  theme: {
    themes: {
      light: {
        primary: "#8F85FF",
        secondary: "#111b47",
        accent: "#8c9eff",
        error: "#b71c1c",
        primaryDash: "#37447E",
        secondaryDash: "#7275FF",
        thirdDash: "#C2CFE0",
        textColorDash: "#5E5873"
      }
    }
  }
};

export default new Vuetify(options);
