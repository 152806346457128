import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    modal: {
      toggle: false,
      mensagem: "",
      tipo: "informacao"
    }
  },
  mutations: {
    setModal(state, payload) {
      if (payload.toggle) {
        state.modal = payload;
      } else {
        state.modal = {
          toggle: false,
          mensagem: "",
          tipo: "informacao"
        };
      }
    }
  },
  getters: {
    getModal(state) {
      return state.modal;
    }
  },
  actions: {},
  modules: {}
});
