<template>
  <v-dialog
    v-model="dialog"
    persistent
    :overlay="true"
    max-width="500px"
    transition="dialog-transition"
  >
    <v-card class="pa-5">
      <div>
        <v-row>
          <v-col cols="10">
            <div class="text-h5 pb-4 pl-2 secondary--text font-weight-bold">
              Alterando item
            </div></v-col
          >
          <v-col cols="2" class="text-right">
            <v-icon @click="closeModal()">mdi-close</v-icon>
          </v-col>
        </v-row>
      </div>
      <div class="formulario pa-3">
        <div class="text-subtitle-1 text-center grey--text font-weight-light">
          Item
        </div>
        <div class="text-h6 text-center pb-5">
          {{ include.nome }}
        </div>
        <v-text-field
          v-model.number="include.qtdItem"
          name="Quantidade"
          label="Quantidade"
          type="number"
          outlined
        ></v-text-field>
        <div>
          <v-text-field
            name="ValorUnit"
            prefix="R$"
            label="Valor Unitário"
            v-model="valorUnit"
            outlined
            v-currency="{
              locale: 'pt-Pt',
              currency: null,
              valueAsInteger: false,
              distractionFree: false,
              precision: 2,
              autoDecimalMode: true,
              valueRange: { min: 0 },
              allowNegative: false
            }"
          />
        </div>
        <div class="text-center">
          <div class="font-weight-bold">Descrição do item</div>
          <div class="font-weight-normal pt-1 grey--text text--darken-1">
            {{ item.descricao ? item.descricao : "Item sem descrição" }}
          </div>
        </div>
        <div class="text-center">
          <v-checkbox
            label="Calcular pela quantidade de formandos"
            v-model="include.calcularPorFormando"
            :value="include.calcularPorFormando"
            class="text-right"
          ></v-checkbox>
        </div>
        <div class="text-center py-5">
          <v-divider></v-divider>
          <div class="grey--text text--darken-1 text-h6 font-weight-light">
            Valor total do item
          </div>
          <div class="total primary--text text-h5 font-weight-bold">
            {{ include.valor | currency }}
          </div>
          <v-divider></v-divider>
        </div>
      </div>
      <div class="pa-2 red--text" v-if="errorArray.length > 0">
        <div class="mb-2">
          Erros encontrados
        </div>
        <ul>
          <li
            class="black--text pa-1 text-subtitle-2"
            :key="index"
            v-for="(item, index) in errorArray"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="acoes pa-2 my-3">
        <v-row>
          <v-col cols="6" class="text-center">
            <v-btn outlined block color="primary" @click="removeItem()"
              >Excluir</v-btn
            >
          </v-col>
          <v-col class="text-center" cols="6">
            <v-btn block color="primary" @click="salvaItem()">Salvar</v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { CurrencyDirective } from "vue-currency-input";

export default {
  props: ["item", "quantidadeAluno"],
  directives: {
    currency: CurrencyDirective
  },
  data() {
    return {
      dialog: true,
      error: false,
      errorArray: [],
      valorUnit: 0,
      qtdAluno: 0,
      qtdProduto: 1,
      include: {
        nome: "",
        valor: 0,
        id: null,
        imgUrl: null,
        qtdItem: 1,
        sigla: "",
        descricao: null,
        calcularPorFormando: false
      }
    };
  },
  watch: {
    "include.qtdItem"() {
      this.calculaValores();
    },
    "include.calcularPorFormando"() {
      this.calculaValores();
    },
    valorUnit() {
      this.calculaValores();
    }
  },
  beforeMount() {
    this.include = { ...this.item };
    this.valorUnit = Number(this.include.valor);
    this.qtdAluno = Number(this.quantidadeAluno);
    this.calcInitVal();
  },
  methods: {
    calcInitVal() {
      let numb_valorUnit = this.convertStringToNumber(this.valorUnit);
      let numb_qtdItem = Number(this.include.qtdItem);
      let numb_qtdAluno = this.convertStringToNumber(this.qtdAluno);
      let typeCalc = this.include.calcularPorFormando;
      if (typeCalc) {
        this.include.valor = numb_valorUnit / numb_qtdItem / numb_qtdAluno;
        this.valorUnit = this.include.valor;
      } else {
        this.include.valor = numb_valorUnit / numb_qtdItem;
        this.valorUnit = this.include.valor;
      }
    },
    convertStringToNumber(valorStr) {
      if (valorStr !== null && valorStr !== undefined && valorStr !== "") {
        let over1 = String(valorStr);
        let over2 = over1.replace("/./g", "");
        let over3 = over2.replace(",", ".");
        let over4 = over3.replace(/[^0-9.-]+/g, "");
        return Number(over4);
      }
      return 0;
    },
    calculaValores() {
      let numb_valorUnit = this.convertStringToNumber(this.valorUnit);
      let numb_qtdItem = Number(this.include.qtdItem);
      let numb_qtdAluno = this.convertStringToNumber(this.qtdAluno);
      if (this.include.calcularPorFormando) {
        this.include.valor = numb_valorUnit * numb_qtdItem * numb_qtdAluno;
      } else {
        this.include.valor = numb_valorUnit * numb_qtdItem;
      }
    },
    verificaDados() {
      this.errorArray = [];
      if (this.include.qtdItem < 1 || typeof this.include.qtdItem == Number) {
        this.errorArray.push("Quantidade de item não pode ser menor que 1");
      }
      if (this.include.valor < 0.01 || typeof this.include.valor == Number) {
        this.errorArray.push("Valor do item não pode ser menor que 0.1");
      }
    },
    removeItem() {
      this.$emit("removeItem", this.include);
    },
    salvaItem() {
      this.verificaDados();
      if (this.errorArray.length > 0) return false;
      this.$emit("saveEdit", this.include);
    },
    closeModal() {
      this.$emit("close");
    }
  }
};
</script>
