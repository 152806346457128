import axios from "axios";

export default {
  cad_plano(payload) {
    return axios.post("/v1/landingpage/cad-plano", payload);
  },
  SolicitarOrcamento(payload) {
    return axios.post("/v1/landingpage/solicitar-orcamento", {
      email: payload.email,
      nome: payload.nome,
      telefone: payload.telefone
    });
  },
  getTiposCalculo() {
    return axios.get("/v1/tipos/tipo-calculo");
  },
  getItensCalculadora() {
    return axios.get("/v1/tipos/itens-calculadora");
  },
  calcularValor(payload) {
    return axios.post("/v1/landingpage/calcular", payload);
  }
};
