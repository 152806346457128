<template>
  <div class="modalBlack" v-if="getModal.toggle == true">
    <div class="null"></div>
    <div class="null"></div>
    <div class="null"></div>
    <div class="null"></div>
    <div class="scope">
      <div
        class="modalCustom"
        :class="{
          informacao: getModal.tipo == 'informacao',
          confirmacao: getModal.tipo == 'confirmacao',
          sucesso: getModal.tipo == 'sucesso',
          erro: getModal.tipo == 'erro'
        }"
      >
        <div class="close">
          <v-icon @click="close()">
            mdi-close
          </v-icon>
        </div>
        <div class="mensagem">
          {{ getModal.mensagem }}
        </div>
        <div class="actions">
          <button class="btn" @click="close()">Ok, fechar</button>
        </div>
      </div>
    </div>
    <div class="null"></div>
    <div class="null"></div>
    <div class="null"></div>
    <div class="null"></div>
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      this.$store.commit("setModal", {
        toggle: false
      });
    }
  },
  computed: {
    getModal() {
      return this.$store.getters["getModal"];
    }
  }
};
</script>

<style lang="scss" scoped>
.modalBlack {
  background-color: rgba(50, 50, 50, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.5fr;
  grid-template-rows: 0.5fr auto 0.5fr;
  z-index: 10000;
  vertical-align: center;
  text-align: center;
}
.modalCustom {
  z-index: 9999;
  display: inline-block;
  width: 400px;
  margin: 0 auto;
  padding: 10px;
  padding-bottom: 20px;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(50, 50, 50, 0.7);
  border-radius: 10px;
  .actions {
    button {
      background-color: rgb(234, 234, 234);
      padding: 10px;
      font-size: 14px;
      border-radius: 5px;
    }
  }
  .mensagem {
    padding: 10px 10px;
    margin-bottom: 15px;
    font-size: 15px;
  }
  .close {
    padding: 0px;
    color: black;
    text-align: right;
    span {
      cursor: pointer;
    }
  }
  div > .btn {
    padding: 10px 20px;
  }
  &.sucesso {
    //border-left: 10px solid rgba(50, 170, 50, 0.1);
    .mensagem {
      color: rgba(50, 50, 50, 1);
    }
    .btn {
      background-color: #8f85ff;
      //background-color: #111b47;
      border: none;
      border-radius: 5px;
      font-weight: 900;
      color: white;
    }
  }
  &.erro {
    border-left: 10px solid rgba(150, 50, 50, 0.1);
    .mensagem {
      color: rgba(150, 50, 50, 1);
    }
    .btn {
      background-color: rgba(180, 20, 20, 0.1);
      color: rgba(90, 20, 20, 0.7);
      border: none;
      border-radius: 5px;
    }
  }
  &.informacao {
    background-color: white;
    border-left: 10px solid rgba(50, 50, 50, 0.1);
    .btn {
      color: rgba(50, 50, 50, 1);
      border: white;
    }
  }
}
</style>
