<template>
  <div v-if="dialog == true">
    <v-layout row justify-center>
      <v-dialog v-model="dialog" persistent max-width="800">
        <v-card class="mx-auto pa-8">
          <div>
            <div class="closeBtn">
              <v-icon size="30" @click="close()">mdi-close</v-icon>
            </div>

            <div v-if="etapa == 1">
              <div class="pa-0 mb-0 text-left ml-3 text-h4 secondary--text">
                Quase lá,
              </div>
              <div class="ml-4 mb-3 text-h6 text-left secondary--text">
                precisamos das seguintes informações.
              </div>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                @submit.prevent="solicitarOrcamento"
              >
                <v-row>
                  <v-col cols="12" md="6">
                    <div class="design text-center py-5">
                      <img
                        src="@/assets/design.svg"
                        class="text-center text-md-left"
                        style="max-width: 250px;"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" class="px-5">
                    <div class="formulario">
                      <v-text-field
                        :rules="nomeRules"
                        v-model="include.nome"
                        label="Nome completo"
                        name="name"
                      ></v-text-field>

                      <v-text-field
                        v-model="include.telefone"
                        placeholder="(48) 0000-0000"
                        label="Telefone"
                        name="phone"
                      ></v-text-field>

                      <v-text-field
                        v-model="include.email"
                        :rules="emailRules"
                        required
                        placeholder="lorem@ipsum.com.br"
                        label="Email"
                        name="email"
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col v-if="listaDeErros.length > 0" cols="12">
                    <div
                      id="IDerros"
                      class="erroList mx-n8 pa-2"
                      style=" color:rgba(120,0,0,1);"
                    >
                      <v-divider />
                      <div
                        class="pa-2 text-left font-weight-normal text-subtitle-1"
                      >
                        Verifique os seguintes erros:
                      </div>
                      <div class="pa-2 text-left black--text text-subtitle-2">
                        <ul>
                          <li
                            v-for="(item, index) in listaDeErros"
                            :key="index"
                          >
                            {{ item.erro }}
                          </li>
                        </ul>
                      </div>
                      <v-divider />
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-row justify="end" align="center">
                      <v-col cols="12" md="6">
                        <v-btn
                          block
                          type="submit"
                          class="botao"
                          color="secondary"
                          :loading="loading"
                          >Quero saber mais</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </div>
            <div v-if="etapa == 2">
              <div class="pa-0 mb-10 text-left ml-3 text-h4 secondary--text">
                Resultado da calculadora
              </div>
              <v-row class="exporValores">
                <v-col cols="12" xs="12" sm="3">
                  <div class="expo">Valor total por aluno</div>
                  <div class="valor text-h6 font-weight-bold">
                    {{ receiver.valorCotaFormando | currency }}
                  </div>
                </v-col>
                <v-col cols="1" sm="1" class="d-none d-sm-flex">
                  <center><div class="bar"></div></center>
                </v-col>
                <v-col cols="12" xs="12" sm="4">
                  <div class="expo">Valor total da formatura</div>
                  <div class="valor text-h6 font-weight-bold">
                    {{ receiver.valorTotal | currency }}
                  </div>
                </v-col>
                <v-col cols="1" xs="12" sm="1" class="d-none d-sm-flex">
                  <center><div class="bar"></div></center>
                </v-col>
                <v-col cols="12" xs="12" sm="3">
                  <div class="expo">Valor da parcela</div>
                  <div class="valor text-h6 font-weight-bold">
                    {{ receiver.valorParcela | currency }}
                  </div>
                </v-col>
              </v-row>
              <div class="text-center third--text text-h6 pa-5">
                Parabéns, processo concluído, fique atento ao seu email,
                receberá mais detalhes em breve.
              </div>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
import serviceCalc from "@/service/calculadora.js";
export default {
  props: ["dialog", "receiver"],
  data() {
    return {
      etapa: 1,
      include: {
        anoFormatura: 0,
        anoInicio: 0,
        email: "", //recebe
        id: 0,
        itens: [],
        mesFormatura: 0, // recebe
        mesInicio: 0,
        nome: "",
        qtdAluno: 0, // recebe
        telefone: "",
        tipoCalculo: "", // recebe
        turmaId: 0,
        usuarioId: 0,
        valorCotaFormando: 0, //recebe
        valorParcela: 0, // recebe
        valorTotal: 0 //recebe
      },
      nomeRules: [v => !!v || "Nome não foi definido"],
      emailRules: [
        v => !!v || "Email é necessario",
        v =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Email é inválido"
      ],
      valid: false,
      termosDeUso: true,
      listaDeErros: [],
      loading: false
    };
  },
  watch: {
    receiver: function(e) {
      this.include.valorCotaFormando = e.valorCotaFormando; // exibido
      this.include.valorTotal = e.valorTotal; // exibido
      this.include.valorParcela = e.valorParcela;
      this.include.itens = e.itens;
      this.include.tipoCalculo = e.tipoCalculo;
      this.include.mesFormatura = e.mesFormatura;
      this.include.anoFormatura = e.anoFormatura;
      this.include.qtdAluno = e.qtdAluno;
    }
  },
  methods: {
    validarDados() {
      this.listaDeErros = [];
      if (!this.include.nome || this.include.nome.length < 4) {
        this.listaDeErros.push({
          erro: "Nome inválido"
        });
      }
      if (
        !this.include.email ||
        !this.include.email.includes("@") ||
        !this.include.email.includes(".")
      ) {
        this.listaDeErros.push({
          erro: "Email inválido"
        });
      }
      if (!this.include.telefone || this.include.telefone.length < 8) {
        this.listaDeErros.push({
          erro: "Número de Telefone inválido"
        });
      }
    },
    validForm() {
      this.$refs.form.validate();
    },
    filterData() {
      this.errorHandleToggle = false;
    },
    solicitarOrcamento() {
      this.loading = true;
      this.validarDados();
      if (this.listaDeErros.length > 0) {
        this.$root.errorModal("Erros encontrados, verifique a lista");
        window.href = "#IDerros";
        this.loading = false;
        return false;
      }
      let includeMe = this.include;
      let solicitacaoOrcamento = {
        email: includeMe.email,
        nome: includeMe.nome,
        telefone: includeMe.telefone
      };
      serviceCalc
        .cad_plano(includeMe)
        .then(resp2 => {
          if (resp2.status == 200) {
            serviceCalc
              .SolicitarOrcamento(solicitacaoOrcamento)
              .then(() => {
                this.sucessReq = true;
                this.sucessType = "orcamento";
                this.etapa = 2;
                this.loading = false;
              })
              .catch(err => {
                this.$root.errorModal(err.data);
                this.loading = false;
              });
          }
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          this.loading = false;
        });
    },
    saibamaisPreCadastro() {
      this.loading = true;
      this.validarDados();
      if (this.listaDeErros.length > 0) {
        this.$root.errorModal("Erros encontrados, verifique a lista");
        this.loading = false;
        return false;
      }
      let dados = this.include;
      serviceCalc
        .cad_plano(dados)
        .then(() => {
          this.etapa = 2;
          this.loading = false;
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          this.loading = false;
        });
    },
    close() {
      this.include.nome = "";
      this.include.email = "";
      this.include.telefone = "";
      this.etapa = 1;
      this.loading = false;
      this.$emit("close", false);
    }
  }
};
</script>

<style lang="scss" scoped>
$btnAction: #111b47;
.closeBtn {
  position: absolute;
  right: 20px;
  top: 20px;
}
.btnFechar {
  color: white;
  background-color: $btnAction;
  width: 80%;
}
.request {
  padding: 10px;
  font-size: 20px;
  color: rgb(30, 30, 30);
  text-align: center;
  .msg {
    padding: 10px 0px;
    font-size: 16px;
  }
  button {
    padding: 10px 14px;
    display: inline-block;
    margin: 0 auto;
  }
}
.actionBTN {
  color: white;
  background-color: #111b47;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  border-radius: 5px;
}
.titulo {
  font-size: 25px;
  padding: 20px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  //text-align: center;
}
.cadastro {
  padding-top: 10px;
  .design {
    img {
      display: inline-flex;
      width: 90%;
    }
    margin: 0 auto;
    align-content: center;
    align-items: center;
    vertical-align: center;
    text-align: center;
  }
  .formulario {
    padding: 20px;
  }
}
.exporValores {
  text-align: center;
  .expo {
    color: #6f7cb2;
    font-size: 16px;
  }
  .valor {
    color: #091133;
    font-size: 22px;
    font-family: "Ubuntu", sans-serif;
    line-height: 40px;
  }
  .bar {
    border-left: 2px solid #6f7cb2;
    max-width: 4px;
    padding: 1px;
    height: 100px;
    opacity: 0.2;
  }
}
</style>
