import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueCurrencyInput from "vue-currency-input";
import VueCurrencyFilter from "vue-currency-filter";
import money from "v-money";
import "@/style/global.scss";
import store from "./store";
import { BACKEND_URL } from "@/constants/bases";
import "./addons";
import moment from "moment";

axios.defaults.baseURL = BACKEND_URL;

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    return Promise.reject(error.response);
  }
);

axios.interceptors.request.use(
  config => {
    return config;
  },
  error => Promise.reject(error)
);

Vue.config.productionTip = false;
Vue.config.errorHandler = (err, vm, info) => {
  console.error(err, vm, info);
};

const pluginOptions = {
  globalOptions: { currency: "BRL" }
};

Vue.use(money, {
  precision: 2,
  decimal: ",",
  thousands: ".",
  masked: false
});

Vue.use(VueCurrencyInput, pluginOptions);
Vue.use(VueCurrencyFilter, [
  {
    symbol: "R$",
    thousandsSeparator: ".",
    fractionCount: 2,
    fractionSeparator: ",",
    symbolPosition: "front",
    symbolSpacing: true,
    avoidEmptyDecimals: undefined
  },
  {
    name: "currencySemDecimal",
    symbol: "R$",
    thousandsSeparator: ".",
    fractionCount: 0,
    fractionSeparator: ",",
    symbolPosition: "front",
    symbolSpacing: true,
    avoidEmptyDecimals: undefined
  }
]);

new Vue({
  vuetify,

  data() {
    return {
      money: {
        precision: 2,
        decimal: ",",
        thousands: ".",
        masked: false
      }
    };
  },

  methods: {
    validaData(dataNasc) {
      if (dataNasc && dataNasc.length == 10) {
        let data = moment(dataNasc, "DD/MM/YYYY");
        if (data.isValid()) {
          if (data.isBefore(moment())) {
            return true;
          }
        }
      }
      return false;
    },
    sucessoModal(mensagem) {
      this.$store.commit("setModal", {
        mensagem: mensagem,
        tipo: "sucesso",
        toggle: true
      });
    },
    infoModal(mensagem) {
      this.$store.commit("setModal", {
        mensagem: mensagem,
        tipo: "informacao",
        toggle: true
      });
    },
    errorModal(mensagem) {
      this.$store.commit("setModal", {
        mensagem: mensagem,
        tipo: "erro",
        toggle: true
      });
    }
  },

  store,
  render: h => h(App)
}).$mount("#app");
