<template>
  <v-app id="app">
    <v-main>
      <modalResultadoCalculadora
        :receiver="recebido"
        @close="handleClose()"
        :dialog="diag"
      />
      <div class="pa-3">
        <v-row justify="center" align="center">
          <v-col cols="12" sm="8" class="text-center">
            <a :href="getURLSite" class=" text-decoration-none">
              <img class="logoheader" src="@/assets/logo-deitada.png" />
            </a>
          </v-col>
          <v-col cols="12" sm="4" class="text-center">
            <a :href="getURLLogin" class=" text-decoration-none">
              <v-btn color="secondary" class="white--text">Entrar</v-btn>
            </a>
          </v-col>
        </v-row>
      </div>
      <v-container class="pb-0 ml-0 mt-12 mx-auto" id="calculadora">
        <v-row class="mx-auto">
          <v-col cols="12" md="6" class="pa-0">
            <div
              :style="
                'background-image: url(' +
                  require('@/assets/fundo-calculadora.svg') +
                  ')'
              "
              style="background-size: cover; background-position: top;background-origin: content-box;"
            >
              <v-img src="@/assets/calculadora.svg" max-width="540" />
            </div>
          </v-col>
          <v-col cols="12" md="6" class="mb-15">
            <div class="calculadora">
              <v-card elevation="0" class="card-calculator px-md-2" tile>
                <div
                  class="pa-5 pa-md-0 py-5 py-md-4 text-md-h4 font-weight-bold text-center text-md-left secondary--text"
                >
                  Calculadora de Eventos
                </div>
                <p
                  class="text-md-h6 text-subtitle-2 third--text font-weight-regular text-center text-md-left"
                >
                  Veja como é fácil criar um plano de arrecadação inteligente
                  com a exclusiva Calculadora de Eventos do Appdaturma.
                </p>
                <v-form>
                  <v-row class="form-calculator">
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        label="Número de participantes"
                        type="number"
                        min="0"
                        hint="Número aproximado de participantes do evento"
                        v-model="form.qtdAluno"
                        :readonly="
                          form.tipoCalculo === 'DESCRICAO_ITENS' &&
                            form.itens.length > 0
                        "
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        label="Data do evento"
                        v-model="data"
                        v-mask="'##/##/####'"
                        placeholder="dd/mm/aaaa"
                        hint="Data real ou aproximada do evento"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-select
                        :items="tiposCalculo"
                        item-text="descricao"
                        item-value="id"
                        label="Calcular usando"
                        @change="changeTipoCalculo"
                      >
                        <template v-slot:append-outer>
                          <v-avatar @click="modalInfoCalculo = true">
                            <v-icon color="blue darken-2">
                              mdi-information
                            </v-icon>
                          </v-avatar>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        v-if="form.tipoCalculo === 'VALOR_PARCELA'"
                        v-model.lazy="form.valorParcela"
                        v-money="$root.money"
                        prefix="R$"
                        label="Valor da parcela"
                      />

                      <v-text-field
                        v-if="form.tipoCalculo === 'VALOR_TOTAL'"
                        v-model.lazy="form.valorTotal"
                        v-money="$root.money"
                        prefix="R$"
                        label="Valor total"
                      />
                      <v-autocomplete
                        v-if="form.tipoCalculo === 'DESCRICAO_ITENS'"
                        v-model="itemParaLista"
                        :items="itensCalculadora"
                        :search-input.sync="buscaItem"
                        label="Itens"
                        name="Itens"
                        item-value="id"
                        item-text="nome"
                        required
                        return-object
                      >
                        <template v-slot:no-data>
                          <div class="pa-2 px-4">
                            <div>O item não existe, deseja acrescentar ?</div>
                            <div>
                              <v-btn
                                @click="acrescentarItemCustom()"
                                color="primary"
                                outlined
                                class="ma-1"
                                ><v-icon>mdi-plus-box</v-icon>
                                {{ buscaItem }}</v-btn
                              >
                            </div>
                          </div>
                        </template>
                        <template v-slot:append-outer>
                          <v-icon
                            v-if="itemParaLista != null"
                            color="primary"
                            @click="addItemNaLista()"
                            v-text="'mdi-plus-box'"
                          ></v-icon>
                          <v-icon
                            v-else
                            disabled
                            @click="addItemNaLista(itemParaLista)"
                            v-text="'mdi-plus-box'"
                          ></v-icon>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="form.tipoCalculo === 'DESCRICAO_ITENS'"
                    >
                      <v-expansion-panels
                        multiple
                        accordion
                        :value="expanded"
                        class="hidden-sm-and-down"
                      >
                        <v-expansion-panel :key="0">
                          <v-expansion-panel-header
                            ><div
                              style="
                                font-weight: 500;
                                font-size: 15px;
                                color: secondary;
                              "
                            >
                              <v-badge
                                color="primary"
                                :content="form.itens.length"
                                :value="form.itens.length"
                              >
                                Itens selecionados</v-badge
                              >
                            </div></v-expansion-panel-header
                          >
                          <v-expansion-panel-content
                            v-if="form.itens.length > 0"
                          >
                            <v-row class="ma-0 pa-0">
                              <v-col cols="4" class="text-subtitle-2 text-left"
                                >Nome</v-col
                              >
                              <v-col
                                cols="4"
                                class="text-subtitle-2 text-center"
                                >Valor</v-col
                              >
                              <v-col
                                cols="2"
                                class="text-subtitle-2 text-center"
                                >Qtd.</v-col
                              >
                              <v-col cols="2" class="text-subtitle-2 text-right"
                                >Ações</v-col
                              >
                            </v-row>
                            <v-row
                              :key="index"
                              v-for="(item, index) in form.itens"
                              class="my-2"
                              justify="center"
                              align="center"
                            >
                              <v-col cols="4" class="text-body-2 text-left">
                                <div>
                                  {{ item.nome }}
                                  <v-avatar
                                    color="grey lighten-3"
                                    class="cursor-point"
                                    size="25"
                                    @click="abrirDescricaoItem(item)"
                                  >
                                    <v-icon size="20"
                                      >mdi-information-variant</v-icon
                                    >
                                  </v-avatar>
                                </div>
                              </v-col>
                              <v-col cols="4" class="text-body-2 text-center">{{
                                item.valor | currency
                              }}</v-col>
                              <v-col cols="2" class="text-body-2 text-center">{{
                                item.qtdItem
                              }}</v-col>
                              <v-col cols="2" class="text-right">
                                <v-avatar
                                  size="45"
                                  style="cursor: pointer"
                                  color="rgba(200,200,200,0.2)"
                                >
                                  <v-icon
                                    size="20"
                                    class="mx-1"
                                    @click="openModalEdit(item)"
                                    color="primary"
                                    >mdi-pencil</v-icon
                                  >
                                </v-avatar>
                              </v-col>
                            </v-row>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                      <div
                        class="blocos hidden-md-and-up"
                        v-if="form.itens.length > 0"
                      >
                        <div class="text-h6 secondary--text">
                          Itens na lista
                          <v-avatar
                            size="35"
                            class="primary--text text-subtitle-1"
                            style="background-color: rgba(200, 200, 200, 0.4)"
                          >
                            {{ form.itens.length }}
                          </v-avatar>
                        </div>
                        <vItemListaCalculadora
                          :key="index"
                          v-for="(item, index) in form.itens"
                          :item="item"
                          @openEditor="openModalEdit(item)"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-form>
                <v-row class="mt-5 pa-3" align="end" justify="end">
                  <v-btn
                    :loading="loadingCalculadora"
                    @click="requestCalculo()"
                    class="px-md-13 br-10 white--text rounded-lg subtitle-1 font-weight-medium"
                    color="secondary"
                    >Calcular</v-btn
                  >
                </v-row>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog max-width="500" v-model="modalInfoCalculo">
        <v-card class="pa-4 white--text" color="info">
          <div class="d-grid grid-col-1-auto centralizaTudo text-left">
            <div class="text-h6 font-weight-bold pl-5">Tipos de calculo</div>
            <v-btn text class="white--text" @click="modalInfoCalculo = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </div>
          <ul class="mt-3">
            <li class="pb-3">
              <strong>Valor da parcela:</strong> se você já tem o valor da
              parcela que cada participante vai pagar
            </li>
            <li class="pb-3">
              <strong>Valor total:</strong> se você quer calcular o valor da
              parcela a partir de um valor total que quer arrecadar
            </li>
            <li class="pb-3">
              <strong>Itens da lista:</strong> Neste caso você precisa saber o
              valor aproximado de cada item da lista para calcular
              automaticamente o valor total e o valor da parcela.
            </li>
          </ul>
        </v-card>
      </v-dialog>
      <vItemEditor
        v-if="modalEdicao"
        :item="itemForEdit"
        :quantidadeAluno="form.qtdAluno"
        @close="fecharModalItem()"
        @removeItem="removeItemFromList"
        @saveEdit="salvarEdicao"
      />
    </v-main>
    <vModal />
  </v-app>
</template>

<script>
import modalResultadoCalculadora from "@/components/modalResultadoCalculadora.vue";
import vItemListaCalculadora from "@/components/vItemListaCalculadora.vue";
import vItemEditor from "@/components/modalEditarItem.vue";
import serviceCalc from "@/service/calculadora";
import vModal from "@/components/modalCustom.vue";
import { APP_URL, SITE_URL } from "@/constants/bases";
import moment from "moment";

export default {
  name: "App",
  components: {
    modalResultadoCalculadora,
    vItemEditor,
    vModal,
    vItemListaCalculadora
  },
  data() {
    return {
      modalInfoCalculo: false,
      modalEdicao: false,
      diag: false,
      data: "",
      expanded: [0],
      tiposCalculo: [],
      itensCalculadora: [],
      itemForEdit: {},
      itemParaLista: "",
      recebido: {},
      buscaItem: "",
      loadingCalculadora: false,
      form: {
        tipoCalculo: "",
        qtdAluno: 0,
        mesFormatura: 0,
        anoFormatura: 0,
        valorTotal: 0,
        valorParcela: 0,
        itens: []
      }
    };
  },
  computed: {
    getURLSite() {
      return SITE_URL;
    },
    getURLLogin() {
      return APP_URL + "/aluno/login";
    }
  },
  beforeMount() {
    serviceCalc.getTiposCalculo().then(resp => {
      this.tiposCalculo = resp.data;
    });
    serviceCalc.getItensCalculadora().then(resp => {
      this.itensCalculadora = resp.data;
    });
  },
  methods: {
    abrirDescricaoItem(item) {
      if (item && item.descricao) {
        this.$root.infoModal(item.descricao);
      } else {
        this.$root.infoModal("Sem descrição");
      }
    },
    filtroDeDados() {
      //diversos
      if (this.form.tipoCalculo == "") {
        this.$root.errorModal("Você não selecionou o tipo de cálculo");
        this.errorHandleToggle = true;
        return false;
      }
      if (this.form.qtdAluno < 1) {
        this.$root.errorModal("É necessário incluir a quantidade de alunos");
        this.errorHandleToggle = true;
        return false;
      }
      //especificos
      if (this.form.tipoCalculo == "DESCRICAO_ITENS") {
        if (this.form.itens.length < 1) {
          this.errorHandleToggle = true;
          this.$root.errorModal(
            "Você não adicionou nenhum item, acrescente um item, para dar início ao cálculo"
          );
          return false;
        }
      }
      if (this.form.tipoCalculo == "VALOR_TOTAL") {
        if (this.form.valorTotal < 1) {
          this.errorHandleToggle = true;
          this.$root.errorModal(
            "Falta adicionar o valor total e não pode ser negativo"
          );
          return false;
        }
      }
      if (this.form.tipoCalculo == "VALOR_PARCELA") {
        if (this.form.valorParcela < 1) {
          this.errorHandleToggle = true;
          this.$root.errorModal(
            "Falta adicionar o valor da parcela e não pode ser negativo"
          );
          return false;
        }
      }
      if (
        isNaN(this.form.anoFormatura) ||
        this.form.anoFormatura == null ||
        this.form.anoFormatura == undefined
      ) {
        this.errorHandleToggle = true;
        this.$root.errorModal("Falta definir a data do evento");
        return false;
      }
      if (
        isNaN(this.form.mesFormatura) ||
        this.form.mesFormatura == null ||
        this.form.mesFormatura == undefined
      ) {
        this.errorHandleToggle = true;
        this.$root.errorModal("Falta definir a data de formatura");
        return false;
      }
      this.errorHandleToggle = false;
    },
    acrescentarItemCustom() {
      let payload = {
        id: "",
        valor: 1200,
        nome: this.buscaItem,
        imgUrl: null,
        qtdItem: 1,
        calcularPorFormando: null,
        sigla: this.buscaItem,
        descricao: ""
      };
      this.form.itens.push(payload);
      this.$root.infoModal("Item adicionado a lista");
    },
    openModalEdit(item) {
      this.itemForEdit = item;
      this.modalEdicao = true;
    },
    salvarEdicao(item) {
      let ar = this.form.itens.find(it => it.id == item.id);
      let indexQuery = this.form.itens.indexOf(ar);
      if (indexQuery != -1) {
        this.form.itens.splice(indexQuery, 1, item);
      } else {
        //teste
      }
      this.modalEdicao = false;
    },
    removeItemFromList(item) {
      let ar = this.form.itens.find(it => it.id == item.id);
      let indexQuery = this.form.itens.indexOf(ar);
      if (indexQuery != -1) {
        this.form.itens.splice(indexQuery, 1);
      } else {
        //teste
      }
      this.modalEdicao = false;
    },
    fecharModalItem() {
      this.modalEdicao = false;
    },
    addItemNaLista() {
      if (!this.itemParaLista) {
        this.$root.infoModal("É necessário selecionar um item");
        return false;
      }
      let Item = this.itemParaLista;
      let quantidadeDoItem = Number(Item.qtdItem);
      let quantidadeDeAlunos = Number(this.form.qtdAluno);
      let valorUnitario = Number(Item.valor);

      if (quantidadeDeAlunos < 1) {
        this.$root.errorModal("Deve ter pelo menos 1 aluno na turma");
        return false;
      }
      if (Item.calcularPorFormando) {
        Item.valor = valorUnitario * quantidadeDoItem * quantidadeDeAlunos;
      }
      this.form.itens.push(Item); // adiciona na lista
      let verificaExistencia = this.itensCalculadora.indexOf(Item);
      if (verificaExistencia > -1) {
        this.itensCalculadora.splice(verificaExistencia, 1);
      }
      this.itemParaLista = null;
    },
    handleClose() {
      this.diag = false;
      this.recebido = {};
    },
    validaData(dataNasc) {
      if (dataNasc && dataNasc.length == 10) {
        let data = this.tratamentoData(dataNasc);
        if (data.isValid()) {
          return true;
        }
      }
      return false;
    },
    tratamentoData(data) {
      if (data && data.length == 10 && data.includes("/")) {
        return moment(data, "DD/MM/YYYY");
      } else {
        return null;
      }
    },
    requestCalculo() {
      this.loadingCalculadora = true;

      var dataInput = this.tratamentoData(this.data);
      var dataHoje = moment();

      if (!dataInput) {
        this.$root.errorModal("Data não está conforme, (dd/mm/yyyy)");
        this.data = "";
        this.loadingCalculadora = false;
        return false;
      }

      if (!dataHoje.isBefore(dataInput, "month")) {
        this.$root.errorModal("Data deve ser maior que o mês atual");
        this.data = "";
        this.loadingCalculadora = false;
        return false;
      }

      let mountMonth = dataInput.month() + 1;
      let mountYear = dataInput.year();
      this.form.anoFormatura = mountYear;
      this.form.mesFormatura = mountMonth;
      this.form.qtdAluno = Number(this.form.qtdAluno);
      this.filtroDeDados();
      this.form.valorTotal = this.converteValor(this.form.valorTotal);
      this.form.valorParcela = this.converteValor(this.form.valorParcela);
      if (this.errorHandleToggle) {
        this.loadingCalculadora = false;
        return false;
      }
      serviceCalc
        .calcularValor(this.form)
        .then(resp => {
          if (resp.status == 200) {
            this.recebido = resp.data;
            this.diag = true;
            this.loadingCalculadora = false;
          }
        })
        .catch(err => {
          this.$store.commit("setModal", {
            mensagem: `Erro: ${err.data}`,
            toggle: true,
            tipo: "erro"
          });
          this.loadingCalculadora = false;
        });
    },
    changeTipoCalculo(tipoCalculo) {
      this.form.tipoCalculo = tipoCalculo;
      this.form.itens = [];
      this.form.valorTotal = 0;
      this.form.valorParcela = 0;
    },
    converteValor(valorStr) {
      if (
        valorStr &&
        valorStr !== null &&
        valorStr !== undefined &&
        valorStr !== ""
      ) {
        // return Number(valorStr.replace(',', '.').replace(/[^0-9.-]+/g, ''))
        return Number(
          valorStr
            .replaceAll(".", "")
            .replace(",", ".")
            .replace(/[^0-9.-]+/g, "")
        );
      }
      return 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.logoheader {
  width: 280px;
}
</style>
